 .foolinks li {
    display: inline-block;
    margin-right: 10px; 
    text-decoration: none;
    
  }
  
  /* .foolinks li::after {
    content: '|';
    margin-left: -10px;
    color: black; 
  } */
  
  .foolinks li:last-child::after {
    content: ''; 
  } 
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 20px;
  }
  
  .logo-section {
    display: flex;
    align-items: center;
  }
  
  .logo-lg {
    max-width: 100px;
    border-radius: 50%;
  }
  
  .logo-sm {
    align-items: center;
    max-width: 70px;
    border-radius: 50%;
  }
  
  .foolinks {
    list-style-type: none;
    padding: 0;
  }
  
  .foolinks li {
    display: inline-block;
    margin-right: 15px;
  }
  
  .foolinks li:last-child {
    margin-right: 0;
  }
  
  .foolinks li a {
    text-decoration: none;
    color: white;
    
  }

  .foolinks li a:hover {
    color:black; /* Change text color to black on hover */
  }
  @media (max-width: 768px) {
    .foolinks {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Two columns */
      gap: 5px; /* Gap between items */
    }
  }

  .foolinks {
    list-style-type: none;
    padding: 0;
  }
  
  .foolinks li {
    margin-bottom: 10px;
  }
  
  
  .foolinks a {
    display: block;
    padding: 5px 0;
  }
  