/* Common styles for .landing-page */
.landing-page {
    position: relative;
    height: 90vh; /* Full viewport height */
    background-image: url('logo1.jpeg');
     /* Background image path */
    background-size: cover;
    /* background-position: center;
    color: #fff; /* Text color */
    /* text-align: center;
    display: flex;
    align-items: center;  */
  }


  
  /* Overlay styles */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(84, 168, 238, 0.555); /* Semi-transparent black overlay */
  }
  
  /* Container styles */
  .container {
    z-index: 1; /* Ensure content is above the overlay */
    position: relative; /* Relative positioning */
  }
  



  /* Media query for mobile devices */
  @media (max-width: 767px) {
    .landing-page {
      max-height: 200px;
     
      
    }
  }
  /* @media (max-width:100px){
    .gif{
      max-height: 10px;
     
    }
  } */
  @media (max-width: 768px) {
    .gif {
      max-height: 120px; /* Adjust the height for smaller screens */
    }
  }