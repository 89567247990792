
@media(max-width:767px){
    .contact-container{
      max-height:100vh;
     
    }
    
  }
  /* @media (max-width: 768px) {
    .contact-content {
      display: none; 
    }
  } */